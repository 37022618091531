class WordShuffle {
    constructor(ScrollTrigger) {

        this.element = document.querySelector('header h2');
        this.word = this.element.querySelector('.word_shuffle');
        this.cursorBlink = this.element.querySelector('.cursor_blink');
        
        this.shuffleParameters = {
            skills: [
                'maker of things', 
                'educator',
                'goofball',
                'industrial design nerd', 
                'father of two',
                'speaker', 
                'lp collector',
                'wanderer of virtual realities', 
                'creative consultant',
                'husband', 
                'artist', 
                'polymath',
                'tech enthusiast', 
                'storyteller', 
                'craftsman',
                'cinema buff', 
                'prompt poet', 
                'maker of things'
            ],
            tail: 4,
            skillP: 15,
            text: 'maker of things',
            delay: 30,
            timeout: 100,
        };

        this.colors = [
            '#abb21c', 
            '#fcc40c', 
            '#70caf9', 
            '#fc940c', 
            '#d55a20', 
            '#eb8e9a'
        ];

        this.state = {
            text: this.shuffleParameters.text,
            skillIndex: 0,
            charIndex: this.shuffleParameters.skillP,
            direction: 'backward',
            delay: this.shuffleParameters.delay,
        };

        this.timer = false
        this.inactive = false;
        this.shouldPause = false;
        this.initListeners(ScrollTrigger);
        this.cursorBlink.classList.remove("paused");
        this.startShuffle(true);

    }

    initListeners(ScrollTrigger) {
        window.addEventListener('blur', () => {
            this.shouldPause = true;
        });
        window.addEventListener('focus', () => {
            this.shouldPause = false;
            if (this.inactive) {
                this.inactive = false;
                this.startShuffle();
            }
        });

        ScrollTrigger.create({
            trigger: this.element,
            start: "top",
            end: "bottom",
            once: false,
            onEnterBack: () => {
                this.shouldPause = false;
                if (this.inactive) {
                    this.inactive = false;
                    this.startShuffle();
                }
            },
            onLeave: () => {
                this.shouldPause = true; // Mark that we should pause after completing the cycle
            },
        });
    }

    getRandomColor() {
        return this.colors[Math.floor(Math.random() * this.colors.length)];
    }

    getRandomChar() {
        return String.fromCharCode(Math.random() * (127 - 33) + 33);
    }

    getRandomColoredString(n) {
        const fragment = document.createDocumentFragment();
        for (let i = 0; i < n; i++) {
            const char = document.createElement('span');
            char.textContent = this.getRandomChar();
            char.style.color = this.getRandomColor();
            fragment.appendChild(char);
        }
        return fragment;
    }

    startShuffle(initial = false) {
        if( this.timer ){clearTimeout(this.timer);}
        if (this.inactive) return;
        cancelAnimationFrame(this.raf);
        
        if (initial) {
            setTimeout(() => {
                this.raf = requestAnimationFrame(() => this.shuffleWord());
            }, this.shuffleParameters.delay * 100); 
        } else {
            this.raf = requestAnimationFrame(() => this.shuffleWord());
        }
    }

    shuffleWord() {
        
        // if (this.inactive) return;
        if (this.shouldPause && this.state.direction === 'forward' && this.state.charIndex === this.shuffleParameters.skills[this.state.skillIndex].length) {
            this.inactive = true;
            this.cursorBlink.classList.remove("paused");
            return;
        }

        const skill = this.shuffleParameters.skills[this.state.skillIndex];

        if (this.state.direction === 'forward') {
            if (this.state.charIndex < skill.length) {
                this.state.text += skill[this.state.charIndex];
                this.state.charIndex++;
            } else if (this.state.delay > 0) {
                this.cursorBlink.classList.remove("paused");
                this.state.delay--;
            } else {
                this.state.direction = 'backward';
                this.state.delay = this.shuffleParameters.delay;
            }
        } else {
            if (this.state.charIndex > 0) {
                this.cursorBlink.classList.add("paused");
                this.state.text = this.state.text.slice(0, -1);
                this.state.charIndex--;
            } else {
                this.state.skillIndex = (this.state.skillIndex + 1) % this.shuffleParameters.skills.length;
                this.state.direction = 'forward';
            }
        }

        this.word.innerHTML = '';
        const textNode = document.createTextNode(this.state.text);
        this.word.appendChild(textNode);
        this.word.appendChild(this.getRandomColoredString(
            Math.min(this.shuffleParameters.tail, skill.length - this.state.charIndex)
        ));

        this.timer = setTimeout(() => this.startShuffle(), this.shuffleParameters.timeout);
    }
}

export default WordShuffle;